<template>  
  <v-container fluid>
    <div class="d-flex flex-wrap justify-space-between">
      <div class="graph text-right" id="div10">
        <v-skeleton-loader type="image" v-if="loading"></v-skeleton-loader>
        <canvas v-show="!loading" id="graph10"></canvas>
        <v-icon @click="extend(10,$event.target)">mdi-fullscreen</v-icon>
      </div>
      <div class="graph text-right" id="div20">
        <v-skeleton-loader type="image" v-if="loading"></v-skeleton-loader>
        <canvas v-show="!loading" id="graph20"></canvas>
        <v-icon @click="extend(20,$event.target)">mdi-fullscreen</v-icon>
      </div>
      <div class="graph text-right" id="div40">
        <v-skeleton-loader type="image" v-if="loading"></v-skeleton-loader>
        <canvas v-show="!loading" id="graph40"></canvas>
        <v-icon @click="extend(40,$event.target)">mdi-fullscreen</v-icon>
      </div>
      <div class="graph text-right" id="div30">
        <v-skeleton-loader type="image" v-if="loading"></v-skeleton-loader>
        <canvas v-show="!loading" id="graph30"></canvas>
        <v-icon @click="extend(30,$event.target)">mdi-fullscreen</v-icon>
      </div>
    </div>
  </v-container>
</template>

<script>
import { parseDate } from "@/utils";
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  data() {
    return {
      loading: true,
      resumen: [
        {
          fecha:2017,
          comuniones:112,
          nAdultos:3449,
          nNinos:879,
          total:4328,
          avAdultos:31,
          piscis:31,
          hotel:31,
          lalanne:7,
          laus:0,
          vdv:0,
          oAragon:3,
          oCat:0,
          perdidas: 20
        },
        {
          fecha:2018,
          comuniones:81,
          nAdultos:2417,
          nNinos:630,
          total:3047,
          avAdultos:30,
          piscis:26,
          hotel:42,
          lalanne:7,
          laus:0,
          vdv:2,
          oAragon:4,
          oCat:0,
          perdidas: 14
        },
        {
          fecha:2019,
          comuniones:73,
          nAdultos:2440,
          nNinos:674,
          total:3114,
          avAdultos:33,
          piscis:18,
          hotel:42,
          lalanne:6,
          laus:2,
          vdv:2,
          oAragon:3,
          oCat:0,
          perdidas: 18
        },
        // {
        //   fecha:2020,
        //   comuniones:28,
        //   nAdultos:800,
        //   nNinos:260,
        //   total:1060,
        //   avAdultos:29,
        //   piscis:3,
        //   hotel:19,
        //   lalanne:2,
        //   laus:2,
        //   vdv:0,
        //   oAragon:2,
        //   oCat:0,
          // perdidas: 7
        // },
        {
          fecha:2021,
          comuniones:49,
          nAdultos:  986,
          nNinos:  222,
          total:  1208,
          avAdultos:  20,
          piscis:  13,
          hotel:  26,
          lalanne:  4,
          laus:  1,
          vdv:  1,
          oAragon:  4,
          oCat:  0,
          perdidas: 16
        },
      ],
      chart1: {},
      chart2: {},
      chart3: {},
      chart4: {},
      specialColorBorder:{
        success: 'rgba(76, 175, 80, 1)',
        info: 'rgba(54, 162, 235, 1)',
        warning: 'rgba(251, 140, 0, 1)',
        error: 'rgba(255, 82, 82, 1)'
      },
      specialColor:{
        success: 'rgba(76, 175, 80, 0.2)',
        info: 'rgba(54, 162, 235, 0.2)',
        warning: 'rgba(251, 140, 0, 0.2)',
        error: 'rgba(255, 82, 82, 0.2)'
      },
      borderColors:[
        'rgba(54, 162, 235, 1)',
        'rgba(181, 20, 66, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 205, 86, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(0, 0, 207, 1)',
        'rgba(64, 255, 88, 1)',
      ],
      colors:[
        'rgba(54, 162, 235, 0.2)',
        'rgba(181, 20, 66, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(0, 0, 207, 0.2)',
        'rgba(64, 255, 88, 0.2)',
      ]
    };
  },
  computed:{
    resumenComp(){
      if (new Date(new Date().getFullYear(), 8,1,12,0,0) < new Date()) return this.resumen.filter(r => r.fecha > 2018 && r.fecha <= new Date().getFullYear()+1)
      else return this.resumen.filter(r => r.fecha > 2018 && r.fecha <= new Date().getFullYear())
    },
    backdrop(){
      if (this.$vuetify.theme.isDark) return '#1E1E1E'
      else return '#FFF'
    },
    grid(){
      if (this.$vuetify.theme.isDark) return '#d39652'
    }
  },
  mounted(){
    this.loading = true
    this.getComuniones()
  },
  methods: {
    extend(e,t){
      if (t.getAttribute("class").split(" ").includes("mdi-fullscreen")) {
        t.setAttribute("class", t.getAttribute("class").replace("mdi-fullscreen", "mdi-fullscreen-exit"))
        document.getElementById("div" + e).setAttribute("class",document.getElementById("div" + e).getAttribute("class").replace("graph", "graphExtended"));
      }
      else{
        t.setAttribute("class", t.getAttribute("class").replace("mdi-fullscreen-exit", "mdi-fullscreen"))
        document.getElementById("div" + e).setAttribute("class",document.getElementById("div" + e).getAttribute("class").replace("graphExtended", "graph"));  
      }
    },  
    parseDate,
    async getComuniones() {
      const resumen = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/listado/comuniontotal`,
      });

      this.loading = false

      this.resumen = [...this.resumen,...resumen.data];
      
      this.resumen.forEach(y => {
        if (y.fecha == "2022") {
          y.perdidas += 12
        }
        if (y.fecha == "2023") {
          y.perdidas += 16
        }
      });

      const self = this

      this.chart1 = new Chart(document.getElementById("graph10"), {
        type: 'line',
        data: {
          labels: [null,...this.resumenComp.slice(2018-this.resumenComp.map(r => r.fecha).sort().reverse()[0]).map(r => r.fecha),null],
          datasets: [
            {
              label: 'CONFIRMADAS',
              tension: 0.25,
              data: [null,...this.resumenComp.slice(2018-this.resumenComp.map(r => r.fecha).sort().reverse()[0]).map(r => r.comuniones),null],
              backgroundColor: [
                this.specialColor["success"],
              ],
              borderColor: [
                this.specialColorBorder["success"],
              ],
              fill: true,
              backgroundColor: this.specialColor["success"],
              borderWidth: 1,
              datalabels: {
                labels:{
                  index:{
                    color: this.specialColorBorder['success'],
                    anchor: 'end',
                    align: 'top',
                    clamp: true,
                    offset: -5,
                  },
                  name:{
                    color: this.specialColorBorder['success'],
                    anchor: 'end',
                    align: 'bottom',
                    clamp: true,
                    formatter: function(value, context) {
                      return Math.round(parseFloat(value / Math.max(parseInt((self.resumenComp.slice(2018-self.resumenComp.map(r => r.fecha).sort().reverse()[0])[context.dataIndex-1]?.comuniones||0)+(self.resumenComp.slice(2018-self.resumenComp.map(r => r.fecha).sort().reverse()[0])[context.dataIndex-1]?.perdidas||0)),1))*100) + '%'
                    }
                  }
                },
              },
            },
            {
              label: 'PERDIDAS',
              tension: 0.25,
              data: [null,...this.resumenComp.slice(2018-this.resumenComp.map(r => r.fecha).sort().reverse()[0]).map(r => r.perdidas),null],
              backgroundColor: [
                this.specialColor["error"],
              ],
              borderColor: [
                this.specialColorBorder["error"],
              ],
              fill: true,
              backgroundColor: this.specialColor["error"],
              borderWidth: 1,
              datalabels: {
                color: this.specialColorBorder['error'],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            }
          ]
        },
        options: {
          maintainAspectRatio: false,

          plugins:{
            title: {
              color: '#d39652',
              display: true,
              text: 'TOTAL COMUNIONES',
              position:'top',
              align:'center',
              font: {
                size: 24,
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 5,
                display: true
              }
            },
          },
        },
        plugins: [ChartDataLabels],
        // plugins:[
        //   {
        //     id: 'custom_canvas_background_color',
        //     beforeDraw: (chart) => {
        //       const {ctx} = chart;
        //       ctx.save();
        //       ctx.globalCompositeOperation = 'destination-over';
        //       ctx.fillStyle = '';
        //       ctx.fillRect(0, 0, chart.width, chart.height);
        //       ctx.restore();
        //     }
        //   }
        // ]
      })

      this.chart2 = new Chart(document.getElementById("graph20"), {
        type: 'line',
        data: {
          labels: [null,...this.resumenComp.map(r => r.fecha),null],
          datasets: [
            {
              label: 'PIS',
              data: [null,...this.resumenComp.map(r => r.piscis),null],
              backgroundColor: [
                this.colors[0],
              ],
              borderColor: [
                this.borderColors[0],
              ],
              fill: false,
              backgroundColor: this.colors[0],
              borderWidth: 1,
              datalabels: {
                color:this.borderColors[0],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'HOT',
              data: [null,...this.resumenComp.map(r => r.hotel),null],
              backgroundColor: [
                this.colors[1],
              ],
              borderColor: [
                this.borderColors[1],
              ],
              fill: false,
              backgroundColor: this.colors[1],
              borderWidth: 1,
              datalabels: {
                color:this.borderColors[1],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'LAL',
              data: [null,...this.resumenComp.map(r => r.lalanne),null],
              backgroundColor: [
                this.colors[2],
              ],
              borderColor: [
                this.borderColors[2],
              ],
              fill: false,
              backgroundColor: this.colors[2],
              borderWidth: 1,
              datalabels: {
                color:this.borderColors[2],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'LAUS',
              data: [null,...this.resumenComp.map(r => r.laus),null],
              backgroundColor: [
                this.colors[3],
              ],
              borderColor: [
                this.borderColors[3],
              ],
              fill: false,
              backgroundColor: this.colors[3],
              borderWidth: 1,
              datalabels: {
                color:this.borderColors[3],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'TOZ&PAL',
              data: [null,...this.resumenComp.map(r => r.toz + r.palacio),null],
              backgroundColor: [
                this.colors[4],
              ],
              borderColor: [
                this.borderColors[4],
              ],
              fill: false,
              backgroundColor: this.colors[4],
              borderWidth: 1,
              datalabels: {
                color:this.borderColors[4],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'ARAGÓN',
              data: [null,...this.resumenComp.map(r => r.oAragon),null],
              backgroundColor: [
                this.colors[5],
              ],
              borderColor: [
                this.borderColors[5],
              ],
              fill: false,
              backgroundColor: this.colors[5],
              borderWidth: 1,
              datalabels: {
                color:this.borderColors[5],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'LLEIDA',
              data: [null,...this.resumenComp.map(r => r.oCat),null],
              backgroundColor: [
                this.colors[6],
              ],
              borderColor: [
                this.borderColors[6],
              ],
              fill: false,
              backgroundColor: this.colors[6],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[6],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            }
          ]
        },
        options: {
          maintainAspectRatio: false,

          plugins:{
            legend: {
              labels: {
                boxWidth: 15
              }
            },
            title: {
              color: '#d39652',
              display: true,
              text: 'ESPACIOS CELEBRACIÓN (%)',
              position:'top',
              align:'center',
              font: {
                size: 24,
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true
            },
          },
        },
        plugins:[ChartDataLabels]
      })

      var ds = []
      this.resumenComp.slice(2022-this.resumenComp.map(r => r.fecha).sort().reverse()[0]).forEach((r,i) => {
        var tot = r.negativa + r.neutra + r.positiva
        ds.push({ 
          label : r.fecha,
          data : [ Math.round((r.negativa / tot) * 100), Math.round((r.neutra / tot) * 100), Math.round((r.positiva / tot) * 100)],
          backgroundColor: this.colors[i],
          borderColor: this.borderColors[i],
          pointBackgroundColor: this.colors[i],
          pointBorderColor: this.borderColors[i],
          pointHoverBackgroundColor: this.colors[i],
          pointHoverBorderColor: this.borderColors[i],
          tooltip: {
            callbacks: {
              label: function(context) {
                return r.fecha + ': ' + context.formattedValue + '%'
              }
            }
          }
         })
      });
      this.chart3 = new Chart(document.getElementById("graph30"), {
        type: 'radar',
        data: {
          labels: ["Valoración negativa", "Valoración neutra", "Valoración positiva"],
          datasets: ds
        },
        options:{
          plugins: {
            title: {
              color: '#d39652',
              display: true,
              text: 'VALORACIÓN (%)',
              position: 'top',
              align:'center',
              font: {
                size: 24,
              }
            }
          },
          scales:{
            r:{
              min: 0,
              max: 100,
              beginAtZero: true,
              ticks:{
                backdropColor: 'rgba(0,0,0,0)',
                callback: (value) => `${value}     `,
                color: '#d39652',
              },
              angleLines:{
                color: this.angleLines
              },
              grid:{
                color: this.grid
              }
            }
          }
        }
      })

      this.chart4 = new Chart(document.getElementById("graph40"), {
        type: 'line',
        data: {
          labels: [null,...this.resumenComp.slice(2021-this.resumenComp.map(r => r.fecha).sort().reverse()[0]).map(r => r.fecha),null],
          datasets: [
            {
              label: 'EXPERIENCIA',
              data: [null,...this.resumenComp.slice(2021-this.resumenComp.map(r => r.fecha).sort().reverse()[0]).map(r => Math.round(r.experiencia)),null],
              backgroundColor: [
                this.colors[0],
              ],
              borderColor: [
                this.borderColors[0],
              ],
              fill: false,
              backgroundColor: this.colors[0],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[0],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'RECOMENDACIÓN',
              data: [null,...this.resumenComp.slice(2021-this.resumenComp.map(r => r.fecha).sort().reverse()[0]).map(r => Math.round(r.recomendacion)),null],
              backgroundColor: [
                this.colors[1],
              ],
              borderColor: [
                this.borderColors[1],
              ],
              fill: false,
              backgroundColor: this.colors[1],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[1],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'REDES SOCIALES',
              data: [null,...this.resumenComp.slice(2018-this.resumenComp.map(r => r.fecha).sort().reverse()[0]).map(r => Math.round(r.rrss)),null],
              backgroundColor: [
                this.colors[2],
              ],
              borderColor: [
                this.borderColors[2],
              ],
              fill: false,
              backgroundColor: this.colors[2],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[2],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'OTROS',
              data: [null,...this.resumenComp.slice(2018-this.resumenComp.map(r => r.fecha).sort().reverse()[0]).map(r => Math.round(r.otraReserva)),null],
              backgroundColor: [
                this.colors[4],
              ],
              borderColor: [
                this.borderColors[4],
              ],
              fill: false,
              backgroundColor: this.colors[4],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[4],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
          ]
        },
        options: {
          maintainAspectRatio: false,

          plugins:{
            legend: {
              labels: {
                boxWidth: 15
              }
            },
            title: {
              color: '#d39652',
              display: true,
              text: 'ORIGEN RESERVA (%)',
              position:'top',
              align:'center',
              font: {
                size: 24,
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 5,
                display: true
              }
            }
          },
        },
        plugins: [ChartDataLabels],
      })
    }
  },
}
</script>

<style>
.graph {
  /* border: 1px solid var(--v-secondary-lighten5); */
  cursor: pointer;
  /* transition: all .5s ease-in-out; */
  height:400px !important; width: calc(100%/3.05) !important;
}
.graph > canvas {
  /* border: 1px solid var(--v-secondary-lighten5); */
  cursor: pointer;
  /* transition: all .5s ease-in-out; */
  max-height:350px !important; width: 100% !important;
}
.graphExtended {
  /* border: 1px solid var(--v-secondary-lighten5); */
  cursor: pointer;
  /* transition: all .5s ease-in-out; */
  height: 800px !important; width: 100% !important;
}
.graphExtended > canvas {
  /* border: 1px solid var(--v-secondary-lighten5); */
  cursor: pointer;
  /* transition: all .5s ease-in-out; */
  max-height:750px !important; width: 100% !important;
}
</style>